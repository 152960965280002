<template>
  <div>
    <div class="row">
      <div class="col-md-6 col-xs-12">
        <b-card>
          <b-card-header>
            Полисы ОСАГО
          </b-card-header>
          <b-card-body
            class="p-0"
          >
            <b-overlay
              :show="insurancesMeta.loading"
            >
              <div class="dashboard-table">
                <b-table
                  :items="insurances"
                  :fields="insuranceFields"
                  size="sm"
                  fixed
                  :tbody-tr-class="insuranceRowClass"
                >
                  <template #cell(number)="{ item }">
                    <a
                      href="#"
                      @click.prevent="$router.push({ name: 'cars-view', params: {id: item.id} })"
                    >
                      {{ item.number }}
                    </a>
                  </template>
                  <template #cell(sheet_driver)="{ item }">
                    <a
                      v-if="item.sheet_driver"
                      href="#"
                      @click.prevent="$router.push({ name: 'drivers-view', params: { id: item.sheet_driver.id }})"
                    >
                      {{ `${item.sheet_driver.last_name} ${item.sheet_driver.first_name} ${item.sheet_driver.middle_name}` }}
                    </a>
                  </template>
                </b-table>
                <button
                  v-if="insurancesMeta.last_page > insurancesMeta.page"
                  class="btn btn-sm btn-primary mt-2"
                  @click="onMoreInsurancesHandler"
                >еще</button>
              </div>
            </b-overlay>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-6 col-xs-12">
        <b-card>
          <b-card-header>
            Техосмотры
          </b-card-header>
          <b-card-body
            class="p-0"
          >
            <b-overlay
              :show="techInspectsMeta.loading"
            >
              <div class="dashboard-table">
                <b-table
                  :items="techInspects"
                  :fields="techInspectsFields"
                  size="sm"
                  fixed
                  :tbody-tr-class="techInspectRowClass"
                >
                  <template #cell(number)="{ item }">
                    <a
                      href="#"
                      @click.prevent="$router.push({ name: 'cars-view', params: {id: item.id} })"
                    >
                      {{ item.number }}
                    </a>
                  </template>
                  <template #cell(sheet_driver)="{ item }">
                    <a
                      v-if="item.sheet_driver"
                      href="#"
                      @click.prevent="$router.push({ name: 'driver-view', params: { id: item.sheet_driver.id }})"
                    >
                      {{ `${item.sheet_driver.last_name} ${item.sheet_driver.first_name} ${item.sheet_driver.middle_name}` }}
                    </a>
                  </template>
                </b-table>
                <button
                  v-if="techInspectsMeta.last_page > techInspectsMeta.page"
                  class="btn btn-sm btn-primary mt-2"
                  @click="onMoreTechInspectsHandler"
                >еще</button>
              </div>
            </b-overlay>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-12">
        <b-card>
          <b-card-body>
            <car-new
              :hide-create-btn="true"
              :filter="{
                marked: 1
              }"
            />
          </b-card-body>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BTable,
  BOverlay,
} from 'bootstrap-vue'
import moment from 'moment'
import { mapState } from 'vuex'
import CarNew from '../cars/components/CarNew.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BTable,
    BOverlay,
    CarNew,
  },
  data() {
    return {
      insurancesMeta: {
        last_page: 1,
        page: 1,
        page_size: 5,
        loading: false,
      },
      insurances: [],
      insuranceFields: [
        {
          key: 'number',
          label: 'Номер',
        },
        {
          key: 'sheet_driver',
          label: 'Водитель',
        },
        {
          key: 'insurance',
          label: 'Дата окончания',
          // eslint-disable-next-line consistent-return
          formatter: item => {
            if (item) {
              return moment(item.date_end).format('DD.MM.YY')
            }
          },
        },
      ],
      techInspectsMeta: {
        last_page: 1,
        page: 1,
        page_size: 5,
        loading: false,
      },
      techInspects: [],
      techInspectsFields: [
        {
          key: 'number',
          label: 'Номер',
        },
        {
          key: 'sheet_driver',
          label: 'Водитель',
        },
        {
          key: 'tech_inspect',
          label: 'Дата окончания',
          // eslint-disable-next-line consistent-return
          formatter: item => {
            if (item) {
              return moment(item.date_end).format('DD.MM.YY')
            }
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      search: state => state.app.globalSearch,
    }),
  },
  watch: {
    search() {
      this.fetchInsurances()
      this.fetchTechInspects()
    },
  },
  mounted() {
    this.fetchInsurances()
    this.fetchTechInspects()
  },
  methods: {
    fetchInsurances() {
      this.insurancesMeta.loading = true
      const params = {
        ...this.insurancesMeta,
        // 'filter[group_id__null]': 1,
        'filter[hide_crm]': 0,
        // 'filter[hide_driver]': 0,
        sort: 'insurance',
        search: this.search,
      }
      this.$axios.get('/cars?populate=insurance,sheetDriver', { params }).then(({ data }) => {
        data.data.forEach(item => {
          this.insurances.push(item)
        })
        this.insurancesMeta.last_page = data.meta.last_page
      }).finally(() => {
        this.insurancesMeta.loading = false
      })
    },
    fetchTechInspects() {
      this.techInspectsMeta.loading = true
      const params = {
        ...this.techInspectsMeta,
        // 'filter[group_id__null]': 1,
        'filter[hide_crm]': 0,
        // 'filter[hide_driver]': 0,
        sort: 'techInspect',
        search: this.search,
      }
      this.$axios.get('/cars?populate=techInspect,sheetDriver', { params }).then(({ data }) => {
        data.data.forEach(item => {
          this.techInspects.push(item)
        })
        this.techInspectsMeta.last_page = data.meta.last_page
      }).finally(() => {
        this.techInspectsMeta.loading = false
      })
    },
    onMoreInsurancesHandler() {
      this.insurancesMeta.page += 1
      this.fetchInsurances()
    },
    onMoreTechInspectsHandler() {
      this.techInspectsMeta.page += 1
      this.fetchTechInspects()
    },
    rowClassByDate(item) {
      const now = moment()
      if (item === null) return 'table-danger'
      const date = moment(item.date_end)
      if (date.isSameOrBefore(now)) return 'table-danger'
      if (date.diff(moment(), 'day') <= 10) return 'table-warning'
      return null
    },
    insuranceRowClass(item, type) {
      if (!item || type !== 'row') return null
      return this.rowClassByDate(item.insurance)
    },
    techInspectRowClass(item, type) {
      if (!item || type !== 'row') return null
      return this.rowClassByDate(item.tech_inspect)
    },
  },
}
</script>
<style>
.dashboard-table{
  max-height: 380px;
  overflow-y: scroll;
}
</style>
