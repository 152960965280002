<template>
  <component :is="view + 'dashboard'" />
</template>
<script>
import LkDashboard from '../lk/LkDashboard.vue'
import ManageDashboard from './ManageDashboard.vue'

export default {
  components: {
    LkDashboard,
    ManageDashboard,
  },
  computed: {
    view() {
      return this.$store.getters['user/role'] === 'user' ? 'lk-' : 'manage-'
    },
  },
}
</script>
